import React, { useEffect, createRef } from "react"
import Layout from "../components/layout"
import silvergraypranaav from "../img/silvergray-pranaav.jpg"
import SEOHeader from "../components/seo-header"
export default function Home() {
  return (
    <div>
      <SEOHeader
        title="Silvergray Photography by Pranaav BR"
        description="Pranaav produces unique, captivating photography, individually designed and curated for concepts and campaigns."
        pinterest="true"
      ></SEOHeader>

      <Layout>
        <div className="container">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <img src={silvergraypranaav} className="img-responsive"></img>
              <p style={{ color: "#9e9e9e", textAlign: "center" }}>
                <br></br> <b>Ph:</b> +91 90030 27159 | +91 95512 11125<br></br>
                <b>Email:</b> pranav@silvergray.in
                <br></br>
                <br></br>
              </p>
              <p style={{ color: "#9e9e9e", textAlign: "justify" }}>
                <br></br>
                <b>About the Photographer</b>
                <br></br>
                <br></br>
                Defined by an ease and technical experience of more than 14
                years, Pranaav has worked for many advertising agencies, design
                groups and direct to clients of all sizes.<br></br>
                <br></br>
                He began his career assisting an prominent photographer in the
                industry and was able to pick up on the professional and
                creative aspects of the job right from the start.<br></br>
                <br></br>
                Pranaav produces unique, captivating photography, individually
                designed and curated for concepts and campaigns.<br></br>
                <br></br> He combines his inherent keen eye for design with
                years of technical knowledge to produce work that is clean,
                polished and captivating, under any conditions.<br></br>
                <br></br> His still life images are characterized by
                contemporary use of light and style that reflects the most
                forward-thinking trends. <br></br>
                <br></br>You can perhaps call it a photographer’s intuition or
                years of accumulated experience, working on location with
                changing conditions and adjusting to the elements is what
                Pranaav does best!<br></br>
                <br></br>
              </p>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
      </Layout>
    </div>
  )
}
